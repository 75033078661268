import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'
import React, { useEffect, useState } from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import './../components/CaseStudy.scss'

import { Waypoint } from 'react-waypoint'
import BlockQuote from '../components/BlockQuote'
import CaseStudyContentFull from '../components/CaseStudyContentFull'
import CaseStudyContentSplit from '../components/CaseStudyContentSplit'
import CaseStudyHero from '../components/CaseStudyHero'
import ClutchReviews from '../components/ClutchReviews'
import ImageSlider from '../components/ImageSlider'
import LogoGridExternal from '../components/LogoGridExternal'
import Related from '../components/Related'
import VideoPlayer from '../components/VideoPlayer'

export const query = graphql`
  query($id: String!) {
    prismicLocationPage(id: { eq: $id }) {
      data {
        meta_description
        meta_title
        canonical {
          uid
          type
        }
        hero {
          alt
          fluid(maxWidth: 1800) {
            src
          }
        }
        hero_text_color
        title {
          text
          html
        }
        body {
          ... on PrismicLocationPageBodyTwoColumn {
            id
            items {
              image {
                url
              }
            }
            primary {
              theme
              background
              section_title {
                text
              }
              reverse_columns
              headline {
                text
              }
              content {
                html
              }
            }
          }
          ... on PrismicLocationPageBodyContentBlock {
            id
            primary {
              theme
              background
              content_title {
                text
              }
              image {
                alt
                fluid(maxWidth: 1200) {
                  src
                }
              }
              headline {
                text
              }
              summary {
                text
                html
              }
              left_column {
                html
              }
              right_column {
                html
              }
            }
          }
          ... on PrismicLocationPageBodyQuoteBlock {
            id
            primary {
              quote_company
              quote_person
              quote_text {
                text
              }
              quote_title {
                text
              }
            }
          }
          ... on PrismicLocationPageBodyVideoBlock {
            id
            primary {
              shareable
              video {
                embed_url
                html
                url
              }
              video_title {
                text
              }
            }
            items {
              video_thumbnail {
                alt
                fluid {
                  src
                }
              }
              sharable
              video {
                embed_url
                html
                url
              }
            }
          }
          ... on PrismicLocationPageBodyImageSliderBlock {
            id
            primary {
              slider_title {
                text
              }
            }
            items {
              slider_image {
                alt
                fluid(maxWidth: 1200) {
                  src
                }
              }
            }
          }
          ... on PrismicLocationPageBodyEmbedBlock {
            id
            primary {
              embed_code {
                text
              }
            }
          }
          ... on PrismicLocationPageBodyLogoGridWExternalLinks {
            id
            primary {
              grid_title {
                text
              }
            }
            items {
              link {
                url
                target
              }
              logo {
                alt
                fluid {
                  src
                }
              }
            }
          }
          ... on PrismicLocationPageBodyClutchReviews {
            id
          }
          ... on PrismicLocationPageBodyCallToAction {
            id
            primary {
              button_text {
                text
              }
              button_link {
                type
                uid
                url
                target
              }
              button_alignment
            }
          }
          ... on PrismicLocationPageBodyRelatedCaseStudies {
            primary {
              related_case_study_title {
                text
              }
            }
            items {
              case_study {
                uid
                document {
                  ... on PrismicCaseStudy {
                    uid
                    type
                    data {
                      title {
                        text
                      }
                      thumb {
                        alt
                        fixed(imgixParams: { ar: "16:9", fit: "crop" }) {
                          src
                        }
                      }
                    }
                  }
                  ... on PrismicArticle {
                    uid
                    type
                    data {
                      title {
                        text
                      }
                      thumb {
                        alt
                        fixed(imgixParams: { ar: "16:9", fit: "crop" }) {
                          src
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const SplitContent = (props) => {
  const [active, setActive] = React.useState(false)

  const onEnter = () => {
    if (!active) {
      setActive(true)
    }
  }

  const theme = props.content.theme
    ? props.content.theme.toLowerCase()
    : 'light'

  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <div
        className={`case-study__section theme-${theme} waypoint ${
          active ? 'waypoint-active' : ''
        }`}
        style={{ backgroundColor: props.content.background }}
      >
        <h4>{props.content.section_title.text}</h4>
        <div className="container container-large">
          <CaseStudyContentSplit {...props} />
        </div>
      </div>
    </Waypoint>
  )
}
const FullContent = (props) => {
  const [active, setActive] = React.useState(false)

  const onEnter = () => {
    if (!active) {
      setActive(true)
    }
  }

  const theme = props.content.theme.toLowerCase()
  const left = props.content.left_column && props.content.left_column.html
  const right = props.content.right_column && props.content.right_column.html

  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <div
        className={`case-study__section theme-${theme} waypoint ${
          active ? 'waypoint-active' : ''
        }`}
        style={{ backgroundColor: props.content.background }}
      >
        <h4>{props.content.content_title.text}</h4>
        <div className="container container-medium">
          <CaseStudyContentFull theme={theme} left={left} right={right}>
            {props.content.image && props.content.image.fluid && (
              <div>
                <div className="case-study-content__media">
                  <img src={props.content.image.fluid.src} />
                </div>
                <div className="case-study-content__spacer medium"></div>
              </div>
            )}

            {theme === 'light' && (
              <div className="case-study__rich-text">
                <h3 className="heading heading--l text--medium">
                  {props.content.headline.text}
                </h3>
                {props.content.summary.html &&
                  props.content.summary.text !== '' && (
                    <div>
                      <div className="case-study-content__spacer medium"></div>
                      <div
                        className="text text--l"
                        dangerouslySetInnerHTML={{
                          __html: props.content.summary.html,
                        }}
                      ></div>
                    </div>
                  )}
              </div>
            )}
            {theme === 'dark' && (
              <div className="case-study__rich-text">
                <h3 className="heading heading--l text--medium">
                  {props.content.headline.text}
                </h3>
                {props.content.summary.html &&
                  props.content.summary.text !== '' && (
                    <div>
                      <div className="case-study-content__spacer medium"></div>
                      <div className="row">
                        <div className="col col-md-8 col-md-offset-2">
                          <div
                            className="text text--m"
                            dangerouslySetInnerHTML={{
                              __html: props.content.summary.html,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            )}
          </CaseStudyContentFull>
        </div>
      </div>
    </Waypoint>
  )
}
const QuoteSlider = (props) => {
  const [active, setActive] = React.useState(false)

  const onEnter = () => {
    if (!active) {
      setActive(true)
    }
  }

  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <div
        className={`case-study__section size--small waypoint ${
          active ? 'waypoint-active' : ''
        }`}
      >
        <div className="container container-medium">
          <BlockQuote content={props.content} item={props.content} />
        </div>
      </div>
    </Waypoint>
  )
}
const VideoBlock = (props) => {
  const videos = props.items

  const [videoIndex, setVideoIndex] = useState(0)
  const [currentVideo, setCurrentVideo] = useState(videos[0])

  const [active, setActive] = React.useState(false)
  const [ready, setReady] = React.useState(false)

  useEffect(() => {
    setCurrentVideo(videos[videoIndex])
  }, [videoIndex])

  const onEnter = () => {
    if (!active) {
      setActive(true)
    }
  }

  const videoReady = () => {
    setReady(true)
  }

  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <div
        className={`case-study__section color-gray waypoint ${
          active && ready ? 'waypoint-active' : ''
        }`}
      >
        <h4>{props.content.video_title.text}</h4>
        <div className="container">
          <VideoPlayer
            videoReady={videoReady}
            videoURL={currentVideo.video.embed_url}
          />
          {/* {currentVideo.sharable && (
            <Share shareURL={currentVideo.video.embed_url} minimal={true} />
          )} */}
        </div>
        {videos.length > 1 && (
          <div className="video-list">
            {videos.map((video, index) => {
              return (
                <div
                  className="video-item"
                  onClick={() => {
                    setVideoIndex(index)
                  }}
                >
                  <img src={video.video_thumbnail.fluid.src} alt="Video" />
                </div>
              )
            })}
          </div>
        )}
      </div>
    </Waypoint>
  )
}

const Slider = (props) => {
  const [active, setActive] = React.useState(false)

  const onEnter = () => {
    if (!active) {
      setActive(true)
    }
  }
  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <div
        className={`case-study__section full-width waypoint ${
          active ? 'waypoint-active' : ''
        }`}
      >
        <h4>{props.content.slider_title.text}</h4>
        <div className="">
          <ImageSlider items={props.items} />
        </div>
      </div>
    </Waypoint>
  )
}
const EmbedCode = (props) => {
  const [active, setActive] = React.useState(false)

  const onEnter = () => {
    if (!active) {
      setActive(true)
    }
  }

  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <div
        className={`case-study__section color-gray waypoint ${
          active ? 'waypoint-active' : ''
        }`}
      >
        <div className="container case-study-content">
          <div
            className="embedCode"
            dangerouslySetInnerHTML={{ __html: props.content.embed_code.text }}
          ></div>
        </div>
      </div>
    </Waypoint>
  )
}

const BlogCTA = ({ item }) => {
  const [active, setActive] = React.useState(false)

  const onEnter = () => {
    if (!active) {
      setActive(true)
    }
  }

  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <div
        className={`slice slice-cta text-${item.button_alignment} waypoint ${
          active ? 'waypoint-active' : ''
        }`}
      >
        <a
          href={item.button_link.url}
          target={item.button_link.target ? item.button_link.target : '_self'}
          className="button button-large button-pink button-round"
          style={{ margin: '1.5rem 0' }}
        >
          {item.button_text.text}
        </a>
      </div>
    </Waypoint>
  )
}

const LocationPage = ({ data, location }) => {
  const pageData = data.prismicLocationPage.data
  const slices = pageData.body

  const meta = {
    descripition: pageData.meta_description,
    title: pageData.meta_title,
    uid: pageData.canonical.uid,
    pageType: pageData.canonical.type,
  }

  return (
    <Layout isDetail={true} location={location}>
      <SEO
        meta={meta}
        description={meta.descripition}
        title={meta.title}
        uid={meta.uid}
        pageType={meta.pageType}
      />
      <div className="case-study page">
        <CaseStudyHero
          type="location"
          textColor={data.prismicLocationPage.data.hero_text_color}
          content={data.prismicLocationPage.data}
        />

        {slices.map((item, index) => {
          switch (item.__typename) {
            case 'PrismicLocationPageBodyTwoColumn':
              return <SplitContent content={item.primary} items={item.items} />
            case 'PrismicLocationPageBodyContentBlock':
              return <FullContent content={item.primary} />
            case 'PrismicLocationPageBodyQuoteBlock':
              return <QuoteSlider content={item.primary} />
            case 'PrismicLocationPageBodyVideoBlock':
              return <VideoBlock content={item.primary} items={item.items} />
            case 'PrismicLocationPageBodyEmbedBlock':
              return <EmbedCode content={item.primary} />
            case 'PrismicLocationPageBodyImageSliderBlock':
              return <Slider content={item.primary} items={item.items} />
            case 'PrismicLocationPageBodyLogoGridWExternalLinks':
              return (
                <LogoGridExternal content={item.primary} items={item.items} />
              )
            case 'PrismicLocationPageBodyClutchReviews':
              return <ClutchReviews />
            case 'PrismicLocationPageBodyClutchReviews':
              return <ClutchReviews />
            case 'PrismicLocationPageBodyCallToAction':
              // console.log(item)
              return (
                <div className="col col-md-10 col-md-offset-1">
                  <BlogCTA item={item.primary} />
                </div>
              )
            case 'PrismicLocationPageBodyRelatedCaseStudies':
              return (
                <div className="container">
                  <Related
                    key={index}
                    label={item.primary.related_case_study_title.text}
                    items={item.items}
                    type="related-study"
                  />
                </div>
              )
            default:
              console.log('No type for: ', item.__typename)
              return null
          }
        })}
      </div>
    </Layout>
  )
}

LocationPage.query = query

export default withPreview(LocationPage)
